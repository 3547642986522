import React, { useState } from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/format'
import cx from 'classnames'
import Slider from 'react-slick'
import styles from './style.module.sass'

const MainImage = ({ info, activeImage, changeImage }) => (
  <div className={styles.imageWrap}>
    <img className={styles.imageSrc} {...srcSetProps(sanityImageUrl(activeImage?.image))} alt={activeImage?.caption} />
    <div className={styles.images}>
      {info.images &&
        info.images.map(image => (
          <div
            className={cx(styles.imagesItem, {
              [styles.imagesItemActive]: activeImage === image,
            })}
            key={image._key}
            onClick={() => changeImage(image)}
          >
            <img
              className={styles.imagesItemSrc}
              {...srcSetProps(sanityImageUrl(image.image))}
              alt={image.image?.caption}
            />
          </div>
        ))}
    </div>
  </div>
)

export default ({ info }) => {
  const [activeImage, setActiveImage] = useState(info.images?.[0])
  const changeImage = image => {
    setActiveImage(image)
  }

  const sliderSettings = {
    arrows: false,
    dots: true,
    autoplay: false,
    slidesToShow: 1,
    infinite: false,
    dotsClass: styles.dots,
  }

  return (
    <div className={styles.info}>
      <div className={styles.wrap}>
        <div className={styles.image}>
          {activeImage && <MainImage info={info} activeImage={activeImage} changeImage={changeImage} />}
          <div className={styles.seen}>
            <p className={styles.seenTitle}>{info.seenIn?.title}</p>
            <ul className={styles.seenLogos}>
              {info.seenIn?.items &&
                info.seenIn.items.map((item, index) => (
                  <li key={item._key}>
                    <img {...srcSetProps(sanityImageUrl(item.image))} alt={item.image?.caption} />
                  </li>
                ))}
            </ul>
          </div>

          <div className={styles.articles}>
            <ul className={styles.articlesItems}>
              {info.articles &&
                info.articles.map((item, index) => (
                  <li key={item._key}>
                    <img {...srcSetProps(sanityImageUrl(item.image))} alt={item.image?.caption} />
                    <p>{item.body}</p>
                    <a href={item.url} target="_blank">
                      {item.linkText}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.text}>
            <p className={styles.tag}>{info.tag}</p>
            <h1 className={styles.title}>{info.title}</h1>
            <a href={info.rating?.url} className={styles.rating} target="_blank">
              <img
                src={sanityImageUrl(info.rating?.image)}
                className={styles.ratingImage}
                alt={info.rating?.image.caption}
              />
              <span className={styles.ratingText}>{info.rating?.linkText}</span>
            </a>
          </div>
          {activeImage && <MainImage info={info} activeImage={activeImage} changeImage={changeImage} />}
          <div className={styles.slider}>
            <Slider {...sliderSettings}>
              {info.images &&
                info.images.map((item, index) => (
                  <div
                    className={cx(styles.sliderItem, { [styles.sliderItemActive]: index === 0 })}
                    key={item._key}
                    data-index={index}
                  >
                    <div className={styles.inside}>
                      <img
                        className={styles.insideImage}
                        {...srcSetProps(sanityImageUrl(item.image))}
                        alt={item.image?.caption}
                      />
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
          <div className={styles.details}>
            <p className={styles.description}>{info.description}</p>
            <ul className={styles.prices}>
              {info.prices &&
                info.prices.map((price, index) => (
                  <li className={styles.pricesItem} key={price._key}>
                    <span>{price.price}</span>
                    <span>{price.information}</span>
                  </li>
                ))}
            </ul>
            <ul className={styles.benefits}>
              {info.benefits &&
                info.benefits.map((benefit, index) => (
                  <li key={benefit._key}>
                    <img src={sanityImageUrl(benefit.image)} alt={benefit.image?.caption} />
                    <span>{benefit.text}</span>
                  </li>
                ))}
            </ul>
            <a href={info.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>
              {info.buttonText}
            </a>
            <p className={styles.availability}>{info.availabilityText}</p>
          </div>

          <div className={styles.facts}>
            <p className={styles.factsTitle}>{info.facts?.title}</p>
            <ul className={styles.factsList}>
              {info.facts?.items &&
                info.facts.items.map((fact, index) => (
                  <li key={fact._key} className={styles.factsItem}>
                    <span>{fact.title}</span>
                    <span>{fact.information}</span>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
