import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import ProductSchema from '../components/Shared/Schema/Product'
import Info from '../components/PdpMultiplePage/Info'
import Science from '../components/PdpMultiplePage/Science'
import Testimonials from '../components/PdpMultiplePage/Testimonials'
import HowItWorks from '../components/PdpMultiplePage/HowItWorks'
import Benefits from '../components/PdpMultiplePage/Benefits'
import Price from '../components/PdpMultiplePage/Price'
import Reviews from '../components/PdpMultiplePage/Reviews'
import Faq from '../components/PdpMultiplePage/Faq'
import Wim from '../components/PdpMultiplePage/Wim'

export default ({ pageContext: { data } }) => {
  return (
    <Layout data={data} signUpUrl="https://app.maximustribe.com/registration" signUpText="Get Started">
      <SEO {...data.seo} />
      <ProductSchema {...data.productSchema} />
      <Info info={data.info} />
      <Science science={data.science} table={data.table} />
      <Testimonials testimonials={data.testimonials} />
      <HowItWorks howItWorks={data.howItWorks} />
      <Benefits benefits={data.benefits} />
      <Price price={data.price} />
      <Reviews reviews={data.reviews} />
      <Faq faq={data.faq} />
      <Wim wim={data.wim} />
    </Layout>
  )
}
