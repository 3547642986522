import React from 'react'
import cx from 'classnames'
import { prepareParagraph, srcSetProps, urlWithSearchParamsHandler, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ howItWorks }) => (
  <div className={styles.howItWorks}>
    <div className={styles.wrap}>
      <div className={styles.top}>
        <h2 className={styles.title}>{howItWorks.title}</h2>
        <p className={styles.description}>{howItWorks.description}</p>
        <a href={howItWorks.linkUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>
          {howItWorks.linkText}
        </a>
      </div>
      {howItWorks.items &&
        howItWorks.items.map((item, index) => (
          <div className={styles.item}>
            <div className={styles.itemInfo}>
              <div className={styles.itemTop}>
                <p className={styles.index}>0{index + 1}</p>
                <p className={styles.step}>{item.title}</p>
                <p className={styles.text}>{prepareParagraph(item.text)}</p>
              </div>
              {index === 0 && (
                <a
                  href={howItWorks.linkUrl}
                  onClick={urlWithSearchParamsHandler}
                  className={cx(styles.link, styles.itemLink)}
                >
                  {howItWorks.linkText}
                </a>
              )}
            </div>
            <div className={styles.itemImage}>
              <img
                {...srcSetProps(sanityImageUrl(item.image))}
                className={styles.itemImageSrc}
                alt={item.image?.caption}
              />
            </div>
          </div>
        ))}
    </div>
  </div>
)
