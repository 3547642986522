import React from 'react'
import cx from 'classnames'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ price }) => (
  <div className={styles.price}>
    <img {...srcSetProps(sanityImageUrl(price.image))} className={styles.background} alt={price.image?.caption} />
    <div className={styles.wrap}>
      <div className={styles.content}>
        <h2 className={styles.title}>{price.title}</h2>
        <p className={styles.description}>{price.description}</p>
        <div className={styles.box}>
          <p className={styles.boxPrice}>{price.details?.price}</p>
          <p className={styles.boxDescription}>{price.details?.description}</p>
          <a
            href={price.details?.buttonUrl}
            onClick={urlWithSearchParamsHandler}
            target="_blank"
            className={styles.boxLink}
          >
            {price.details?.buttonText}
          </a>
          <p className={styles.boxAvailability}>{price.details?.availabilityText}</p>
        </div>
      </div>
    </div>
  </div>
)
