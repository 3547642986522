import React from 'react'
import cx from 'classnames'
import { srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ benefits }) => (
  <div className={styles.benefits}>
    <div className={styles.wrap}>
      {benefits.items &&
        benefits.items.map((item, index) => (
          <div className={styles.item}>
            <img {...srcSetProps(sanityImageUrl(item.image))} alt={item.image?.caption} />
            <span>{item.text}</span>
          </div>
        ))}
    </div>
  </div>
)
